.Content {
  margin: 0px 0px 50px 0px;
}

.Courses {
  padding: 40px 60px;
}

.Courses .icon-grid {
  padding: 10px 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}

.icon-grid .courses {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}

.icon-grid .courseLink {
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #03045e;
  border-radius: 12px;
  box-shadow: 1px 1px 4px 1px #ade8f4;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.icon-grid .courseLink:hover {
  cursor: pointer;
  transform: scale(1.02);
  background-color: #dddddd;
}

.courses button {
  border-radius: 4px;
  border: 1px solid #03045e;
  padding: 5px 20px;
  background-color: #ade8f4;
  transition: all 0.3s ease-in-out;
}

.courses button:hover {
  cursor: pointer;
  background-color: #90e0ef;
}

.coverimage {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .Courses .icon-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 35px;
    align-items: center;
    justify-content: center;
  }

  .icon-grid .courses {
    gap: 10px;
  }
}
