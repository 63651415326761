.generated-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.generator-button,
.answer-button {
  max-width: 200px;
  background-color: #90e0ef;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
}

.generator-button:hover,
.answer-button:hover {
  background-color: #48cae4;
  color: black;
}

.Disclaimer {
  text-align: center;
  line-height: 2px;
}

.Questions {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: black;
  font-size: 1.1em;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5;
}

/* necessary? */
.question-title {
  background-color: #ffffff;
  border: 1px solid #03045e;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-content p {
  margin: 0;
}

.answer-content {
  margin-top: 10px;
  background-color: #ade8f4;
  border: 1px solid #00b4d8;
  padding: 15px;
  border-radius: 8px;
}

.answer-content p {
  line-height: 1.5;
}

p:has(.katex) {
  margin-top: 6px;
  margin-bottom: 6px;
}

/* Animations */
@keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}

.spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s linear infinite;
}