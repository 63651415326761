.Resources {
  padding: 15px 20px;
}

@media only screen and (max-width: 768px) {
  .Resources {
    padding: 15px 15px;
    margin-bottom: 50px;
  }
}
