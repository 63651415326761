.about {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.About-Content {
  padding: 30px 50px;
}

.About-Content h1 {
  color: black;
  font-size: 2em;
  font-family: Arial, Helvetica, sans-serif;
}

.Text {
  padding: 0 20px;
  color: black;
  font-size: 1.1em;
  font-family: Arial, Helvetica, sans-serif;
}

.Text p {
  margin-bottom: 5px;
}

.Text ul {
  list-style-type: disc;
  padding-left: 30px;
  margin: 5px 0;
}

.Text ul li {
  margin-bottom: 5px;
}
