.Content {
  margin: 0px 0px 50px 0px;
}

.Units {
  padding: 20px 60px;
}

.Units .icon-grid {
  padding: 10px 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}

.icon-grid .unitLink {
  width: 240px;
  height: 240px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #023e8a;
  border-radius: 12px;
  box-shadow: 1px 1px 4px 1px #ade8f4;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.icon-grid .unitLink:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.icon-grid .unitLink img {
  width: 240px;
  height: 240px;
  object-fit: contain;
  background-color: white;
}

.units {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.units button {
  background-color: #ade8f4;
  border: 1px solid #03045e;
  border-radius: 4px;
  padding: 5px 20px;
  transition: all 0.3s ease-in-out;
}

.units button:hover {
  cursor: pointer;
  background-color: #90e0ef;
}

@media only screen and (max-width: 768px) {
  .Units .icon-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 35px;
    align-items: center;
    justify-content: center;
  }
}
